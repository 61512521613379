import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { IconButton } from '@bibliocommons/base-buttons';
import { StarOutline, Star } from '@bibliocommons/base-icons';
import { Dropdown, DropdownMenuItem, DropdownMenuDivider } from '@bibliocommons/base-dropdown';
import { MY_EVENTS_BASE_URL } from 'app/constants/EventsConstants';
import EventsActions from 'app/actions/EventsActions';

import './AddToInterestedEvents.scss';

export default function AddToInterestedEvents({ eventId, seriesId, savedEvent }) {
  const dispatch = useDispatch();
  const interested = savedEvent?.get('eventIds')?.includes(eventId) ?? false;

  function addToInterestedEvents() {
    dispatch(EventsActions.addToInterestedEvents({ eventId, seriesId }));
  }

  function removeFromInterestedEvents() {
    dispatch(
      EventsActions.removeFromInterestedEvents({ eventId, seriesId, registrationId: savedEvent?.get('registration') })
    );
  }

  function renderDropdownLabel() {
    return (
      <span className="dropdown-label">
        <Star />
      </span>
    );
  }

  function renderSavedEventDropdown() {
    return (
      <Dropdown
        right
        dataKey="interested-event-dropdown"
        aria-label="Interested Event"
        buttonTag={IconButton}
        renderLabel={renderDropdownLabel}
      >
        <DropdownMenuItem
          item={Immutable.Map({
            label: <FormattedMessage id="remove_from_my_events" />,
            value: 'remove-from-my-events'
          })}
          handleClick={removeFromInterestedEvents}
        />
        <DropdownMenuDivider />
        <DropdownMenuItem>
          <Link dataKey="go-to-my-events-link" to={`${MY_EVENTS_BASE_URL}/upcoming/interested`}>
            <FormattedMessage id="go_to_my_events" />
          </Link>
        </DropdownMenuItem>
      </Dropdown>
    );
  }

  return (
    <div className="cp-add-to-interested-events">
      {interested ? (
        renderSavedEventDropdown()
      ) : (
        <IconButton
          dataKey="add-to-interested-events-button"
          className="star-icon-button"
          handleClick={addToInterestedEvents}
          aria-label="Save Event as Interested"
        >
          <StarOutline />
        </IconButton>
      )}
    </div>
  );
}

AddToInterestedEvents.propTypes = {
  eventId: PropTypes.string.isRequired,
  seriesId: PropTypes.string.isRequired,
  savedEvent: ImmutablePropTypes.map
};
